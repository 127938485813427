import React, { Suspense } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CircularProgress, Box } from '@mui/material';

const PtInitiatedIntakePage = React.lazy(() => import('./intake/PtInitiatedIntakePage'));
const IntakeApp = React.lazy(() => import('./intake/IntakeApp'));

function App() {
  const [searchParams] = useSearchParams();
  global.mkey = searchParams.get('mkey');

  return (
    <Suspense
      fallback={(
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    >
      {global.mkey ? <IntakeApp /> : <PtInitiatedIntakePage />}
    </Suspense>
  );
}

export default App;
